@import 'src/styles/common';

.container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($dark, 0.1);
  z-index: $z-index--form;

  &.nestedContainer {
    z-index: $z-index--sub-form;
  }

  .stackView {
    width: 520px;
    min-width: auto;
  }

  .nestedStackView {
    width: 360px;
    min-width: auto;
  }

  .stackViewContent {
    padding: 20px;
  }
}

.save {
  margin-left: 20px;
}
