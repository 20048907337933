@import 'src/styles/common.scss';

.rolesTooltip {
  font-size: 14px;
  line-height: 1.29;
  color: rgba($white, 0.8);

  .rolesTooltipTitle {
    font-weight: 600;
    line-height: 1;
    color: $white;
    margin-bottom: 5px;
  }
}

.roles {
  display: inline-flex;
  align-items: center;
}

.rolesBadge {
  margin-left: 10px;
}

.tooltip {
  font-size: 0;
}

.exportButton {
  height: 35px;
  line-height: 35px;
}

.newUserButton {
  margin-left: auto;
}
.logoutButton {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}