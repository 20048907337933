@import 'src/styles/common';

.input {
  .label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    color: $dark;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
