@import 'src/styles/common';

.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 11px 0;

  .text {
    overflow: hidden;
    margin-right: auto;
  }

  .title {
    @include font($fontSize15, $fontWeightRegular, $lineHeight3);

    color: $primary_blue;
    text-decoration: none;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .subtitle {
    @include font($fontSize10, $fontWeightRegular, $lineHeight1);

    margin-top: 5px;
    color: rgba($dark, 0.6);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .warning {
    @include font($fontSize14, $fontWeightRegular, $lineHeight1);

    margin-top: 5px;
    color: $red;
  }

  .edit {
    @include font($fontSize12, $fontWeightRegular, $lineHeight3);

    padding: 0;
    color: $primary_blue;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 10px;
  }

  .revoke {
    @include font($fontSize12, $fontWeightRegular, $lineHeight3);

    padding: 0;
    color: $primary_blue;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: rgba($black, 0.1);
  }
}
