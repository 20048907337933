@import 'src/styles/common';

.section {
  margin-right: -20px;
  width: calc(100% + 20px);
  padding-bottom: 34px;

  .sectionContent {
    padding: 15px 20px 0 0;
  }

  .sectionIcon {
    color: $primary_blue;
  }
}
