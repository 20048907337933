@import 'src/styles/common';

.text {
  @include font($fontSize14, $fontWeightRegular, $lineHeight3);
}

.setAlternativeLogin {
  margin-top: 15px;
}

.removeAlternativeLogin {
  margin-top: 15px;
  margin-left: 15px;
}

.grantPrivilege {
  margin-top: 15px;
}

.externalToolBlock {
  margin-top: 15px;
}
