@import 'src/styles/common.scss';

.container {
  padding: 30px;

  td {
    a {
      color: $primary_blue;
    }
  }

  .filters {
    display: flex;
    padding: 20px;

    > div,
    > input {
      width: 245px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .actions {
    display: inline-block;
    width: 76px;
  }

  .actions-button {
    padding-left: 10px;
    padding-right: 8px;
  }

  .actions-icon {
    position: relative;
    top: -1px;
    width: 7px !important;
    fill: $white;
    margin: 0 0 0 6px !important;
    height: 4px !important;
  }

  .actions-dropdown {
    top: 23px !important;
    left: -37px !important;
  }
}
